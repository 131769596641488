<template>
  <v-card v-if="hasRecords" class="mb-4">
    <v-card-title>
      {{date}}
    </v-card-title>

    <v-card-text>
      <v-chip
        v-for="record in records"
        :key="record.id"
        class="mr-2 mb-2 white--text"
        :color="getClass(record)"
        label
        small
      >
        <span>{{record.name}}</span>
        <span v-if="record.record_type == 'cost'">
          ({{getCostTypeLabel(record.type)}})
        </span>
        <span class="ml-2">{{record.amount}}</span>
        <span @click="remove(record.id)" class="ml-2 cursor-pointer">&times;</span>
      </v-chip>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    date: {
      type: String,
      required: true
    },
  },
  methods: {
    getCostTypeLabel(type) {
      return this.$t(`cost.type.${type}`)
    },
    getClass(record) {
      return this.$helper.getAccountingRecordTypeColorClass(record.record_type)
    },
    remove(id) {
      this.$apopup.base({
        title: '確定要從試算中移除記錄?',
        content: '執行後僅會從"目前頁面"中的搜尋記錄移除, 並不會實際刪除該筆記錄',
        applyCallback: () => {
          this.$store.commit('accounting/removeRecord', id)
        },
      })
    },
  },
  computed: {
    hasRecords() {
      return this.records.length > 0
    },
    records() {
      return this.$store.getters['accounting/recordsOfDate'](this.date)
    },
  },
}
</script>